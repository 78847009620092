import React from "react"
import { Link } from "gatsby"
import Modal from "../modal/procurement-modal"

import { StaticImage } from "gatsby-plugin-image"
import goldlight from "../../images/new-images/qb-logo-2022-transparent.png"

class HeaderSolution extends React.Component {
  render() {
    const { location } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    return (
      <header
        className={location.pathname === rootPath ? "homepage" : "mainpages"}
      >
        <div
          id="nav-menu"
          className="menu-container-solution sticky-on"
          style={{ boxShadow: "0px 3px 6px #00000029" }}
        >
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-9 col-md-3">
                <div className="nav-brand">
                  <Link aria-label="link" to="/">
                    <StaticImage
                      imgClassName="img-fluid"
                      className="w-75 h-auto"
                      src="../../../static/assets/qcg-logo-color-2021.jpg"
                      alt="logo"
                      layout="constrained"
                      priority
                      // width={195}
                      // height={195}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-2 col-md-5 text-center">
                <div className="d-none d-lg-inline d-md-flex">
                  <img
                    src={goldlight}
                    alt="Gold Light"
                    className="header-qb-partner"
                  />
                </div>
              </div>
              <div className="col text-right">
                <div className="d-none d-lg-inline d-md-flex justify-content-end">
                  <Modal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default HeaderSolution
