import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/procurement-modal"

import "./hero.css"

const HeroProcurementAutomation = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "warehouse-inventory.jpeg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-home hero-procurement-banner"
        // preserveStackingContext
        style={{ backgroundPosition: "bottom", height: "680px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-procurement-automation">
                <h3 className="page-title font-weight-medium mt-0 mb-4">
                  Ready to Automate Your Procurement Processes?
                </h3>
                <p className="mb-0" style={{ paddingBottom: "20px" }}>
                  Whether you’re struggling with invoicing costs, vendor
                  relationships, or too many wasted hours, human errors, and
                  expensive mistakes, we can automate away the pain in your
                  procurement process.
                </p>
                <p>
                  Drop us a line about the problems or slowdowns in your
                  procurement process and we’ll follow up with solutions
                  designed to your specific needs.
                </p>

                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroProcurementAutomation
